<!-- 添加、编辑字典项 -->
<template>
    <el-dialog
        :visible="viewIsReady"
        @close="handleClose"
        title="查看导出参数"
        width="800px"
    >

        <ul class="params-list">
            <li v-for="(par, key) in info" :key="key">
                {{ key }}: {{ par }}
            </li>

        </ul>

        <div slot="footer">
            <el-button @click="handleClose" type="primary">确定</el-button>
        </div>
    </el-dialog>
</template>

<script>
export default {
    name: 'ViewParams',
    props: ["params"],

    data () {
        return {
            viewIsReady: false,
            info: {}
        }
    },

    watch: {
        params (params, old) {
            if (params && params !== old) {
                this.viewIsReady = true;
                this.info = params;
            }
        }
    },

    methods: {
        handleClose () {
            this.viewIsReady = false;
            this.info = {};
            this.$emit("update:params", null);
        }
    }
}
</script>

<style lang='scss' scoped>
    .params-list {
        display: flex;
        flex-wrap: wrap;

        li {
            margin-right: 10px;
            margin-bottom: 10px;
            padding: 5px 10px;
            background-color: whitesmoke;
            border: 1px solid rgba($color: #000000, $alpha: .1);
        }
    }
</style>