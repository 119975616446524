
import forList from "@/mixin/forList";
import ViewParams from "./ViewParams.vue";
export default {
	name: "DownloadList",
    mixins: [forList],
    components: {ViewParams},

	data() {
		return {
            viewingParams: null, // 查看详情的数据
            checkedlist: [],     // 选中的数据
		};
	},

	computed: {
        bulkFreezing () {
            return !this.checkedlist.length
        },
	},

	methods: {

        // 获取列表数据
        async fetchData() {
			const loading = this.$loading();

            try {
                const res = await this.$axios({
                    url: "/api/download/findByPage",
                    params: {...this.extractParams()}
                });
				if (res.code === 2000) {
                    this.total = res.data.total;
                    this.tableData.splice(0, this.tableData.length,
                        ...res.data.list.map(item => {
                            if (item.path) item.path = this.$baseURL + "/" + item.path;
                            return item;
                        }));

                    loading.close();

				} else if (res.code !== 1003)
					throw res
                
            } catch (reason) {
				console.warn("获取数据失败", reason);
				this.$message({
					message: reason.msg || "获取数据失败",
					type: "warning"
				});

				loading.close();
            }
        },

        // 点击查看参数
        // handleQueryBtn (index) {
        //     const item = this.tableData[index];
        //     this.viewingParams = JSON.parse(item.content)
        // },

        // el-table 选中事件，更新 data
        handleSelectionChange(e) {
            this.checkedlist.splice(0, this.checkedlist.length, ...e);
        },

        // 批量删除
        async handleDelete (id) {
            let loadingRef, ids, isSingle = false;
            if (parseInt(id)) {
                ids = id,
                isSingle = true;
            } else {
                ids = this.checkedlist.map(item => item.id).join()
            }

            try {
                await this.$confirm(isSingle ? "确定删除吗" :
                    "确定要删除选中的导出文件吗", "系统提示");
                loadingRef = this.$loading();
                const res = await this.$oxios({
                    url: "/api/download/delRemind",
                    method: "post",
                    data: { ids }
                });
				if (res.code === 2000) {
					this.$message({
						message: "删除成功",
						type: "success"
					});
					loadingRef && loadingRef.close();
					this.fetchData();
	
				} else if (res.code !== 1003) 
					throw res;

            } catch (reason) {
				if (reason !== "cancel") {
					this.$message({
						message: reason.msg || "删除失败",
						type: "warning"
					});
				}
				loadingRef && loadingRef.close();
            }
        },

        // 点击下载按钮
        async handleDownload (data) {
            if (!data.path) return;
            window.open(data.path, "download");

            try {
                const res = await this.$oxios({
                    url: "/api/download/updateNum",
                    method: "post",
                    data: { id: data.id }
                })
				if (res.code === 2000) {
                    data.downloadnum++

				} else if (res.code !== 1003) 
					throw res;
                
            } catch (reason) {
                console.warn(reason);
            }
        }
	},

	created () {
		this.auth.delete = this.$hasAuthFor("api/download/delRemind");
	},
};